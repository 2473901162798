import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { PASSWORD_REGEX } from 'src/app/resources/regex-validators';
import { matchPasswords } from 'src/app/validators/password-match.validator';
import { PasswordInputComponent } from '../password-input.component';

@Component({
  selector: 'app-password-element',
  templateUrl: './password-element.component.html',
  styleUrls: ['./password-element.component.scss'],
  imports: [NgClass, TranslateModule, ReactiveFormsModule, PasswordInputComponent, NgIf],
  standalone: true
})
export class PasswordElementComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() passwordLabel: string;
  @Input() repeatPasswordLabel: string;
  @Input() resetForm: Subject<boolean>;

  passwordForm: FormGroup = this.fb.group(
    {
      password: ['', [Validators.required, Validators.pattern(PASSWORD_REGEX)]],
      repeatPassword: ['', [Validators.required]]
    },
    {validators: matchPasswords}
  );

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
  ) { }

  get password(): FormControl {
    return this.passwordForm.get('password') as FormControl;
  }

  get repeatPassword(): FormControl {
    return this.passwordForm.get('repeatPassword') as FormControl;
  }

  ngOnInit(): void {
    this.passwordForm.statusChanges
      .pipe(
        takeUntil(this._ngUnsubscribe$),
      )
      .subscribe(() => {
        this.form.get(this.controlName).setValue(this.password.value);
        this.form.get(this.controlName).setErrors(this.passwordForm.errors);
      });
    this.resetForm?.pipe(
        takeUntil(this._ngUnsubscribe$),
      )
      .subscribe(v => {
        this.passwordForm.reset();
      });
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
